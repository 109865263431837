import BImage from 'gatsby-background-image';
import React from "react";
import Layout from "../components/common/layout";
import Readmore from "../components/common/readMore";
import SEO from "../components/common/seo";
import useImage from "../hooks/use-image";
import Why from '../components/devsecops/why';
import Technologies from '../components/devsecops/technologies';
import Mountains from "../images/careers/mountains.svg";
// TODO: SEO Information

const DevSecOps = () => {
    const { mdaasLaptop, mdaasDesktop } = useImage();
    const mdaasSrc = [
        mdaasLaptop.sharp.fluid,
        { ...mdaasDesktop.sharp.fluid, media: `(min-width: 1024px)` },
    ];
    return (
        <Layout>
            <SEO 
                title="DevSecOps" 
                description='What can DevSecOps do for your App Deployment?' 
                keywords={[
                    "DevSecOps", 
                    "DevOps", 
                    "CI/CD", 
                    "Software Automation", 
                    "Modern Software Delivery", 
                    "Continuous Integration", 
                    "Continuous Delivery", 
                    "Software Security Scan", 
                    "Automated Deployment"
                ]} 
            />
            <BImage
                Tag="section"
                fluid={mdaasSrc}
                className="devsecops-hero text-white relative p-6 lg:pl-20 lg:pt-24 inner-shadow-3xl"
            >
                <h1 className="relative z-10 text-xl font-medium 2xl:text-2xl">
                    Our Services
                </h1>
                <h2 className="relative z-10 uppercase font-bold text-2xl md:text-2xl lg:text-3xl xl:text-4xl 3xl:text-5xl">
                    What Can DevSecOps
                    <br/> Do For Your
                    <br/> App Development
                </h2>
                <Readmore minHeight={108}>
                    <p className="relative z-20 mt-6 leading-relaxed text-lg 2xl:text-xl max-w-lg">
                        Clarity has vast experience in building
                        highly useable and maintainable pipelines
                        for organizations across Government and industry.
                        Using DevSecOps standards, our engineers
                        design, execute, test, and optimize development
                        pipelines for rapid quality deployments. By enabling
                        communication between automation tools, we build
                        pipelines suited to any environment and use case.
                    </p>
                </Readmore>
            <span className="gradient-overlay-left z-0" />
            </BImage>
            <Why/>
            <Technologies />
            <Mountains />
        </Layout>
    )
}

export default DevSecOps