import React from "react";
import { Fade } from "react-awesome-reveal";

import Gitlab from "../../images/devsecops/gitlab.svg";
import Docker from "../../images/devsecops/docker.svg";
import AWS from "../../images/devsecops/aws.svg";
import IOS from "../../images/devsecops/ios.svg";
import Android from "../../images/devsecops/android.svg";
import Flutter from "../../images/devsecops/flutter.svg";
import Cocoapods from "../../images/devsecops/cocoapods.svg";
import Gradle from "../../images/devsecops/gradle.svg";
import Jenkins from "../../images/devsecops/jenkins.svg";
import JFrog from "../../images/devsecops/jfrog.svg";
import NowSecure from "../../images/devsecops/nowsecure.svg";
import SDElements from "../../images/devsecops/sdelements.svg";
import Anchore from "../../images/devsecops/anchore.svg";
import Terraform from "../../images/devsecops/terraform.svg";
import Sonarqube from "../../images/devsecops/sonarqube.svg";

import Ticker from "react-ticker";

const Technologies = () => {
    return (
        <section id="tech-list">
            <div className="p-6 pb-24 lg:pb-24 mx-auto container text-copy-primary">
                <h2 className="text-center text-3xl lg:text-4xl font-semibold  xl:text-5xl">
                    By using leading industry tools, our experts build the right pipeline for you.
                </h2>
            </div>
            <div className="p-6 lg:pb-24 grid gap-24 justify-items-center grid-cols-1 lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2">
                <Fade direction="down" triggerOnce cascade>
                    <Gitlab className="h-24 w-24" />
                    <Docker className="h-24 w-24" />
                    <AWS className="h-24 w-24" />
                    <IOS className="h-24 w-24" />
                    <Android className="h-24 w-24" />
                    <Flutter className="h-24 w-24" />
                </Fade>
            </div>
            <div className="lg:pb-24 p-6">
                <Ticker offset="5">
                    {() => (
                        <span className="flex gap-24">
                            <Cocoapods className="h-24 w-24" />
                            <Gradle className="h-24 w-24" />
                            <Jenkins className="h-24 w-24" />
                            <JFrog className="h-24 w-24" />
                            <NowSecure className="h-24 w-24" />
                            <SDElements className="h-24 w-24" />
                            <Terraform className="h-24 w-24" />
                            <Sonarqube className="h-24 w-24" />
                            <Anchore className="h-24 w-24 mr-24" />
                        </span>
                    )}
                </Ticker>
            </div>
        </section>
    )
}

export default Technologies;