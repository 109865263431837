import React, { useState } from "react";
import Down from "../../images/common/down.svg";
import Up from "../../images/common/up.svg";

export default function ReadMore({ children, minHeight }) {
  const [visible, setVisible] = useState(false);
  const maxHeight = visible ? `fit-content` : `${minHeight}px`;
  return (
    <>
      <div className="read-more" style={{ maxHeight }}>
        {children}
      </div>
      <button
        className="text-ci-blue-400 flex relative z-10"
        role="button"
        onClick={() => setVisible((prevState) => !prevState)}
      >
        {visible ? "Read less" : "Read more"}
        {visible ? <Up /> : <Down />}
      </button>
    </>
  );
}
