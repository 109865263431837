import React from 'react';
import { Fade } from "react-awesome-reveal";
import AppDev from "../../images/devsecops/app-dev.svg";
import FasterTime from "../../images/devsecops/faster-time.svg";
import SecScan from "../../images/devsecops/sec-scan.svg";
import Automation from "../../images/devsecops/automation.svg";
import ConsistentAppDev from "../../images/devsecops/consistent-appdev.svg";
import Governance from "../../images/devsecops/governance.svg";

const WhyIcons = [
    {
        title: "App Development",
        icon: <AppDev className="h-24 w-24" />,
        content: "App development on iOS, Flutter, Android, and web",
    },
    {
        title: "Faster Time",
        icon: <FasterTime className="h-24 w-24" />,
        content: "Faster time to market for applications"
    },
    {
        title: "Security Scanning",
        icon: <SecScan className="h-24 w-24" />,
        content: "Security Scanning"
    },
    {
        title: "Automation",
        icon: <Automation className="h-24 w-24" />,
        content: "Automation of testing and approvals"
    },
    {
        title: "Consistent App Dev",
        icon: <ConsistentAppDev className="h-24 w-24" />,
        content: "Consistent application development"
    }, 
    {
        title: "Governance",
        icon: <Governance className="h-24 w-24" />,
        content: "Governance to ensure adherence to custom regulations"
    }
]

const Why = () => (
    <section className="lg:pt-20" id="why">
      <div className="p-6 pb-24 lg:pb-24 mx-auto container text-copy-primary">
        <Fade direction="down" triggerOnce cascade>
          <h2 className="text-center text-3xl lg:text-4xl font-semibold  xl:text-5xl">
            Why Clarity?
          </h2>
          <p className="text-center leading-relaxed text-lg md:text-xl lg:leading-relaxed max-w-2xl mx-auto mt-8">
             Our pipeline engineering expertise delivers:
          </p>
        </Fade>
        <ul
          id="why-clarity"
          className="lg:mt-12 xl:mt-32 grid grid-cols-1 gap-6 lg:gap-20 xl:gap-24 md:grid-cols-2 lg:grid-cols-3 lg:ml-24 lg:mr-24"
        >
          {WhyIcons.map(({ title, content, icon }) => (
            <li
              key={title}
              className="mx-auto items-center text-center sm:text-left sm:items-left md:w-full lg:text-left flex flex-col md:items-start mt-12 lg:mt-0 lg:w-3/4 xl:w-full xl:my-12"
            >
              <Fade direction="down" triggerOnce cascade>
                {icon}
                <p className="text-lg mt-6 md:text-xl lg:leading-relaxed">
                  {content}
                </p>
              </Fade>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
  
  export default Why;